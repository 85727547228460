import gsap from 'gsap';
import $ from '../core/Dom';

const DELAY = 4000;

export default el => {
    const dom = $(el);
    const items = dom.find('[data-color]').nodes;

    let timerId = null;
    let offsetY = 0;
    let offsetX = 0;

    const rotateNodes = () => items.push(items.shift());

    const getNextColor = () => items[1].dataset.color;

    const animateOut = () => {
        const node = items[0];
        const backgroundColor = getNextColor();
        gsap.to(document.body, { duration: 1, backgroundColor, ease: 'power2.inOut'});
        gsap.to(node, { duration: 0.3, delay: 0.3, opacity: 0, ease: 'power2.out' });
        gsap.to(node, { duration: 0.6, y: `-${offsetY}`, x: offsetX, ease: 'power2.in', onComplete: () => {
            rotateNodes();
            animateIn();
        }});
    };

    const killTimer = () => {
        if (timerId) {
            clearTimeout(timerId);
        }
    };

    const delayedAnimateOut = () => {
        killTimer();
        timerId = setTimeout(animateOut, DELAY);
    };

    const animateIn = () => {
        const node = items[0];
        gsap.set(node, { y: offsetY, x: `-${offsetX}`, opacity: 0 });
        node.classList.remove('hidden');
        gsap.to(node, { duration: 0.3, opacity: 1, ease: 'power2.in' });
        gsap.to(node, { duration: 0.6, y: 0, x: 0, ease: 'power2.out', onComplete: delayedAnimateOut });
    };

    const onResize = () => {
        if (window.innerWidth < 1160) {
            offsetX = '45vw';
            offsetY = 0;
        } else {
            offsetX = 0;
            offsetY = '30vh';
        }
    };

    const init = () => {
        onResize();
        delayedAnimateOut();
        window.addEventListener('resize', onResize);
    };

    const destroy = () => {
        window.removeEventListener('resize', onResize);
        gsap.killTweensOf(items);
        gsap.killTweensOf(document.body);
        killTimer();
    };

    return {
        init,
        destroy
    };
};
