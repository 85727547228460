import gsap from 'gsap';
// import Viewport from './core/Viewport';
import Components from './core/Components';
// import lazySizes from './lib/lazysizes';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Components.init();
};

require('doc-ready')(init);
