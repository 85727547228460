import gsap from 'gsap';

const instances = [];

export default class Expandable {

    constructor(el) {
        this.el = el;
        this.plus = el.querySelector('[data-plus]');
        this.target = document.querySelector(this.el.getAttribute('href'));
        this.combologo = document.querySelector('[data-combo-logo]');
        this.expanded = false;
        this.clickOutsideHandler = this.clickOutsideHandler.bind(this);
        this.clickHandler = this.clickHandler.bind(this);
        this.keyHandler = this.keyHandler.bind(this);
        this.close = this.close.bind(this);
        this.init();
        instances.push(this);
    }

    init() {
        this.el.addEventListener('click', this.clickHandler);
    }

    destroy() {
        this.el.removeEventListener('click', this.clickHandler);
        this.destroy();
    }

    closeOthers() {
        instances.forEach(instance => {
            if (instance !== this) {
                instance.close(true);
            }
        });
    }

    open() {
        if (!this.expanded) {
            this.closeOthers();
            this.expanded = true;
            this.el.setAttribute('aria-expanded', 'true');
            this.plus.innerHTML = '–';
            this.target.classList.add('pointer-events-auto');
            this.target.classList.remove('invisible', 'max-mp:hidden');
            gsap.fromTo(this.target, { height: 0 }, {
                height: 'auto',
                duration: 0.5,
                ease: 'power2.inOut'
            });
            document.body.addEventListener('keyup', this.keyHandler);
            document.body.addEventListener('click', this.clickOutsideHandler);
            document.body.classList.add('js-menu-open');
        }
    }

    close(external) {
        if (this.expanded) {
            this.expanded = false;
            this.el.setAttribute('aria-expanded', 'false');
            this.plus.innerHTML = '+';
            gsap.to(this.target, {
                height: 0,
                duration: 0.5,
                ease: 'power2.inOut',
                onComplete: () => {
                    this.target.classList.remove('pointer-events-auto');
                    this.target.classList.add('invisible', 'max-mp:hidden');
                }
            });
            document.body.removeEventListener('keyup', this.keyHandler);
            document.body.removeEventListener('click', this.clickOutsideHandler);
            if (!external) {
                document.body.classList.remove('js-menu-open');
            }
        }
    }

    keyHandler(e) {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            this.close();
        }
    }

    clickOutsideHandler(e) {
        if (this.expanded) {
            if (e.target !== this.el && !this.el.contains(e.target) && e.target !== this.target && !this.target.contains(e.target)) {
                this.close();
            }
        }
    }

    clickHandler(e) {
        e.preventDefault();
        if (this.expanded) {
            this.close();
        } else {
            this.open();
        }
    }

}
